button {
  &:focus {
    outline: none;
  }
}

.btn {  
    @apply .flex;
    @apply .justify-center;
    @apply .items-center;
    @apply .rounded;
    @apply .text-grey-dark;
    @apply .font-semibold;
    @apply .bg-white;
    @apply .py-2;
    @apply .px-3;

    &.selected {
      @apply .bg-grey-lightest;
    }

    &:hover {
      @apply .bg-grey-lightest;
    }

    @responsive {
      &.btn-lg {
          @apply py-4;
          @apply px-6;
          @apply .text-base;
      }
    }

    &.btn-cta {
      @apply .text-white;
      @apply .bg-brand;
    } 

    &.btn-cta:hover {
      @apply .bg-brand-light;
      //background-image: linear-gradient(-180deg, #bada2b 0%, #c1de40 100%);
    }

    &.btn-red {
      @apply .text-white;
      @apply .bg-red;
    } 

    &.btn-red:hover {
      @apply .bg-red-light;
      //background-image: linear-gradient(-180deg, #bada2b 0%, #c1de40 100%);
    }
}

.btn-outline {
  @apply .flex;
  @apply .justify-center;
  @apply .items-center;
  @apply .rounded;
  @apply .text-xs;
  @apply .text-grey-dark;
  @apply .font-semibold;
  @apply .uppercase;
  @apply .tracking-wide;
  @apply .bg-white;
  @apply .px-3;
  @apply .py-2;
  @apply .border;
  @apply .border-grey-light;
  //padding-top: 0.6875rem;
  //padding-bottom: 0.6875rem;
  &.dark-outline {
    @apply .border-grey;
  }
  &:disabled {
    @apply .opacity-25;
  }

}

.btn-group {
  @apply .inline-flex;

  .btn {
    @apply .rounded-none;
    @apply .border;
  }

  .btn:first-child {
    @apply .rounded-l;
    @apply .rounded-r-none;
    @apply .border;
  }

  .btn:last-child {
    @apply .rounded-r;
    @apply .rounded-l-none;
    @apply .border;
  }
}

.loader {
    color: transparent !important;
    pointer-events: none;
    position: relative;
}

.loader:after {
    @apply .border-2;
    @apply .border-grey-darker;
    animation: spinAround 500ms infinite linear;
    border-radius: 290486px;
    border-right-color: transparent;
    border-top-color: transparent;
    content: "";
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    position: relative;
    position: absolute;
    left: 44.5%;
    top: 21.5%;
    //left: calc(50% - (1em / 2));
    //top: calc(50% - (1em / 2));
    position: absolute !important;
}

@keyframes spinAround {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
