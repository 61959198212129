:root {
/*  --brand: #3490dc;
  --brand2: #dc8734;*/
  --test: 230, 118, 53;

  --red-darkest: #3b0d0c;
  --red-darker: #621b18;
  --red-dark: #cc1f1a;
  --red: #e3342f;
  --red-light: #ef5753;
  --red-lighter: #f9acaa;
  --red-lightest: #fcebea;
  --red-opacity: 204, 31, 26;

  --orange-darkest: #462a16;
  --orange-darker: #613b1f;
  --orange-dark: #de751f;
  --orange: #f6993f;
  --orange-light: #faad63;
  --orange-lighter: #fcd9b6;
  --orange-lightest: #fff5eb;
  --orange-opacity: 230, 118, 53;

  --yellow-darkest: #453411;
  --yellow-darker: #684f1d;
  --yellow-dark: #f2d024;
  --yellow: #ffed4a;
  --yellow-light: #fff382;
  --yellow-lighter: #fff9c2;
  --yellow-lightest: #fcfbeb;
  --yellow-opacity: 255, 237, 74;

  --green-darkest: #0f2f21;
  --green-darker: #1a4731;
  --green-dark: #1f9d55;
  --green: #38c172;
  --green-light: #51d88a;
  --green-lighter: #a2f5bf;
  --green-lightest: #e3fcec;
  --green-opacity: 31, 157, 85;
}

.theme-whitespider {
	--brand: #3490dc;
	--brand-opacity: 52, 144, 220;
	--brand-light: #6cb2eb;
	--brand-dark: #0F7ECF;
	--brand-darker: #0a6bb1;

	&.bg-login {
		background-image:url(/imgs/bg-authentication.jpg);
	}
}

.theme-hardyfisher {
	--brand: #d93f15;
	--brand-opacity: 217, 63, 21;
	--brand-light: #ff805d;
	--brand-dark: #bf330d;
	--brand-darker: #a52705;

	&.bg-login {
		background-image:url(/imgs/bg-authentication-hardy-fisher.jpg);
	}
}

.theme-exclusivenetworks {
	--brand: #a4027d;
	--brand-opacity: 163, 17, 124;
	--brand-light: #ca029b;
	--brand-dark: #761f63;
	--brand-darker: #59184b;

	&.bg-login {
    /*background-image:url(/imgs/bg-authentication-exclusive-networks.jpg);*/
    background-color: #a4027d
	}
}

.theme-cdw {
	--brand: #bb2121;
  --brand-opacity: 187, 33, 33;
  --brand-light: #da2b2b;
  --brand-dark: #981b1b;
  --brand-darker: #751515;

	&.bg-login {
		background-image:url(/imgs/bg-authentication.jpg);
	}
}

.theme-kinetic {
	--brand: #f0760b;
	--brand-opacity: 240, 118, 11;
	--brand-light: #fcd9b6;
	--brand-dark: #de5916;
	--brand-darker: #ce4d0d;
}
