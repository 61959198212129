input[type=text], input[type=password], input[type=email], textarea {
	@apply .appearance-none;
	@apply .text-grey-dark;
	@apply .border;
	@apply .border-grey-light;
	@apply .rounded;
	@apply .p-4;
	
	&.has-icon {
		@apply .pl-10;
	}

	&.has-error {
		@apply .border;
		@apply .border-red-lighter;
	}
	
	&.search {
		@apply .pl-0;
		@apply .pr-8;
		@apply .p-3;
		@apply .rounded-full;
		@apply .bg-white;
		@apply .text-grey-dark;
		@apply .border-solid;
		@apply .border-2;
		@apply .border-grey-light;
		width: 185px;
	}

	@screen sm {
		&.search {
			width: 335px !important;
		}
	}

	&:focus {
		outline: none;
	}
}

label.file-upload {
    @apply .border-2;
	@apply .border-brand;
	@apply .bg-brand-light;
	@apply .text-white;
	@apply .text-sm;
	@apply .font-semibold;
	@apply .uppercase;
    @apply .px-4;
    @apply .py-2;
    @apply .rounded;
    @apply .cursor-pointer;
    text-shadow: 0px 1px 1px #70818a;
}

input[type="file"] {
    display: none;
}
